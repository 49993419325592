// see: https://buzzfeed.atlassian.net/browse/HUFFPOST-5679

const { isEntry } = window.HP.params;
const entryContent = isEntry && document.querySelector('.js-main-content-list');

if (isEntry && entryContent) {
  const regex = /xid:\{xid\}/gi;
  const links = Array.from(entryContent.querySelectorAll('a'));
  const partnerLinks = links.filter((link) => decodeURIComponent(link.getAttribute('href')).match(regex));

  if (partnerLinks.length) {
    const generateUUID = () => {
      const timestamp = Date.now();
      const randomChar1 = String.fromCharCode(Math.floor(Math.random() * 26) + 97);
      const randomChar2 = String.fromCharCode(Math.floor(Math.random() * 26) + 97);
      const randomChar3 = String.fromCharCode(Math.floor(Math.random() * 26) + 97);
      return `fr${timestamp}${randomChar1}${randomChar2}${randomChar3}`;
    };
    partnerLinks.forEach((plink) => {
      plink.addEventListener('click', (event) => {
        event.preventDefault();
        try {
          const { href } = plink;
          const newHref = decodeURIComponent(href).replace(regex, `xid:${generateUUID()}`);
          window.open(newHref, '_blank');
        } catch (e) {
          console.error('XID Replace Error:', e);
          window.open(plink.href, '_blank');
        }
      });
    });
  }
}
